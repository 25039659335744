import { PropType } from 'vue';
import { Props } from '../../types/core';
import { Link } from '../../types/link';

// Model -------------------------------------------------------------------------------------------

export interface SimpleLinkListModel {
	uid: string;
	links: Link[];
}

// Props -------------------------------------------------------------------------------------------

export const SimpleLinkListProps: Props<SimpleLinkListModel> = {
	uid: {
		type: String as PropType<SimpleLinkListModel['uid']>,
		required: true,
	},
	links: {
		type: Array as PropType<SimpleLinkListModel['links']>,
		required: true,
	},
};
